.wl-table-head {
  // background-color: white;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin: 0 16px;
  padding: 12px 0px;
  flex-wrap: wrap;
  h2 {
    font-size: 20px;
  }
}

.ant-menu-horizontal {
  line-height: 60px !important;
}

.tableClass tr:nth-child(even) {
  background-color: #fbfbfb;
}

.tableClass {
  background-color: white;
  margin: 0 2px;
  border-bottom: 1px solid #9e9a9a;
  border-collapse: collapse;
}

.tableClass th:last-of-type,
.tableClass td:last-of-type {
  border-right: 1px solid #9e9a9a !important;
}

.tableClass td,
.tableClass th {
  border: 0.5px solid #9e9a9a;
}

.tableClass th,
.tableClass .ant-table-thead .ant-table-row-expand-icon-cell {
  background-color: #e0e0e0 !important;
}

.worklog-table {
  .ant-table-cell {
    padding: 3px 4px !important;
  }
}
.ant-table-expanded-row .ant-table-cell {
  background-color: white;
  padding: 8px;
}

.filter-label {
  font-size: 14px;
}

.menu-item {
  color: #222222;
  font-size: 14px;
  font-weight: 600;
}

.assignee-status {
  .ant-select-selection-item {
    color: black;
  }
}

.company-name {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.c-form-item {
  margin-bottom: 12px;
}

.c-form-item-label::after {
  content: ":";
  position: relative;
  margin-block: 0;
  margin-inline-start: 2px;
  margin-inline-end: 8px;
}

.w-240 {
  width: 260px;
}
